<template>
  <div :class="isMobile ? 'Demo-DOH pt-3' : 'Demo-DOH'" id="demo_doh">
    <header
      class="bg-clr sticky-top flex-d c-header c-fixed-height c-header-light c-header-fixed c-header-with-subheader"
    >
      <div style="margin-top: auto; margin-bottom: auto">
        <div
          class="logo pr-1 mobile-pl-1 mx-2"
        >
          <div>
            <a
              href="https://www.doh.gov.ae/covid-19/Health-Workforce-Management-System"
              target="_blank"
              class="p-1 d-inline-block"
              ><img src="/img/white-logo-doh.svg" class="mw-100 h-auto"
            /></a>
          </div>
        </div>
      </div>
      <span class="d-flex flex-column logo-title linespcad title">
        <span>
          <small
            >Kawader - Smart platform for Abu Dhabi's Healthcare
            Workforce</small
          >
        </span>
        <span>
          <small>كــــوادر- منصة ذكية لإدارة الكوادر الصحية في أبوظبي</small>
        </span>
      </span>
      <div
        class="d-flex align-items-center right-action"
        :class="{ 'mr-3': !isMobile }"
      >
        <ul v-if="!isMobile" class="c-header-nav">
          <li class="c-header-nav-item dropdown mx-3 d-none d-md-block p-1">
            <CDropdown inNav class="c-header-nav-items">
              <template #toggler>
                <a class="custom-nav-link"
                  ><span class="header-nav d-block justify"
                    ><small>Living in</small></span
                  >
                  <span class="header-nav d-block justify"
                    ><small>Abu Dhabi</small></span
                  >
                </a>
              </template>
              <CDropdownItem href="https://www.adek.gov.ae/" target="_blank">
                <small>Education</small></CDropdownItem
              >
              <CDropdownItem
                href="https://www.centralbank.ae/en"
                target="_blank"
              >
                <small>Banking </small></CDropdownItem
              >
              <CDropdownItem
                href="https://u.ae/en/information-and-services/visa-and-emirates-id/emirates-id"
                target="_blank"
              >
                <small>Emirates ID </small></CDropdownItem
              >
              <CDropdownItem href="https://tcaabudhabi.ae/" target="_blank">
                <small>Tourism </small></CDropdownItem
              >
            </CDropdown>
          </li>
          <li class="c-header-nav-item dropdown mx-3 d-none d-md-block p-1">
            <CDropdown inNav class="c-header-nav-items">
              <template #toggler>
                <a class="custom-nav-link"
                  ><span class="header-nav d-block justify"
                    ><small>Careers</small></span
                  >
                  <span class="header-nav d-block justify"
                    ><small> & Training</small></span
                  >
                </a>
              </template>
              <CDropdownItem
                href="https://www.doh.gov.ae/en/programs-initiatives/meed"
                target="_blank"
              >
                <small>Residency Programmes </small></CDropdownItem
              >
              <CDropdownItem
                href="https://www.doh.gov.ae/en/programs-initiatives/meed"
                target="_blank"
              >
                <small
                  >Administrative & Fellowship Opportunities
                </small></CDropdownItem
              >
              <CDropdownItem
                href="https://www.doh.gov.ae/en/programs-initiatives/meed"
                target="_blank"
              >
                <small>Other Training Opportunities </small></CDropdownItem
              >
            </CDropdown>
          </li>
          <li class="c-header-nav-item dropdown mx-3 d-none d-md-block p-1">
            <CDropdown inNav class="c-header-nav-items">
              <template #toggler>
                <a class="custom-nav-link"
                  ><span class="header-nav d-block justify"
                    ><small>Medical</small></span
                  >
                  <span class="header-nav d-block justify"
                    ><small>Licensing</small></span
                  >
                </a>
              </template>
              <CDropdownItem
                href="https://www.doh.gov.ae/en/pqr"
                target="_blank"
              >
                <small
                  >How to Apply for your Medical License</small
                ></CDropdownItem
              >
            </CDropdown>
          </li>
        </ul>
        <CButton
          name="pqr"
          class="btn-white btn-pill text-primary mb-1 license-eligiblity"
          @click="redirectToPQR()"
        >
          <small>License Eligibility</small>
        </CButton>
      </div>
    </header>
    <section>
      <div class="m-2 nxt-45 spacingHomeBtn" :class="{'pt-4':isMobile}">
        <div class="mr-0 ml-auto d-table">
          <CButton
            name="home"
            color="primary"
            shape="pill"
            @click="redirectToHome()"
          >
            Back
          </CButton>
          <!-- <CButton
            name="home"
            color="primary"
            shape="pill"
            style="margin-left: 5px"
            @click="redirectToAddPublicJob()"
          >
            Post A New Job
            <sup
              ><span style="font-size: 8px; font-weight: normal"
                >Recruiter</span
              ></sup
            >
          </CButton> -->
        </div>
      </div>
      <div class="container-fluid public-job-board" id="public-job-board">
        <jobBoard />
      </div>
    </section>
    <footer
      class="page-footer f-bg font-small white text-white c-footer c-footer-fixed"
    >
      <div>
        <div class="ml-1 copy-right">
          © 2011 – 2020 OHR Ltd or its affiliates
        </div>
      </div>
      <div class="ml-auto d-flex flex-column">
        <span class="mr-1"
          ><span class="fw-b"
            ><div class="logo-adjustment">powered by</div>
            TALENT</span
          ><span class="fw-5">FIND™</span></span
        >
      </div>
    </footer>
  </div>
</template>

<script>
import jobBoard from "@/pages/JobBoard";
import { getLocalOrgDetail } from "@/helpers/helper";
export default {
  name: "DemoDOH",
  components: {
    jobBoard,
  },
  data() {
    return {
      getLocalOrgDetail,
      isMobile: false,
    };
  },
  computed: {
    isJobBoard() {
      let page = "Public Job Board DOH";
      return this.$router.currentRoute.name == page ? true : false;
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 420px)"
    ).matches;
    this.isMobile = isMobile;
  },
  methods: {
    redirectToHome() {
      this.$router.push({
        path: getLocalOrgDetail()?.path_acronym
          ? `/${getLocalOrgDetail()?.path_acronym}`
          : `/`,
      });
    },
    redirectToPQR() {
      this.$router.push({
        path: "/doh/pqr-check",
      });
    },
    redirectToAddPublicJob() {
      this.$router.push({
        path: `/add/public/job`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Demo-DOH {
  .logo {
    width: 9rem;
  }
  .logo-title small {
    font-size: 12px;
  }
  .bg-clr {
    color: #ffffff !important;
  }
  .c-header.c-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .c-header[class*="bg-"] {
    border-color: rgba(0, 0, 21, 0.1);
  }
  .bg-clr {
    background: #0a5e67;
  }
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
  .hover-decoration-none:hover {
    text-decoration: none;
  }
  .public-job-board{
    overflow-y: inherit;
    height:calc(100vh - 100px);
  }
  @media (max-width: 480px) {
    .logo {
      width: 8rem !important;
    }
    .logo-title small {
      font-size: 10px !important;
    }
    .license-eligiblity {
      font-size: 12px !important;
      padding: 6px !important;
    }
    .right-action {
      padding-right: 4px !important;
    }
    .right-action small {
      font-size: 11px !important;
    }
  }
}
.custom-nav-link {
  span {
    font-size: 14px;
    cursor: pointer;
  }
}
@media (max-width: 441px) {
  .spacingHomeBtn {
    margin-top: 15px !important;
  }
}
@media (max-width: 767px) and (min-width: 442px) {
  .spacingHomeBtn {
    margin-top: 25px !important;
  }
}
@media (max-width: 972px) and (min-width: 768px) {
  .spacingHomeBtn {
    margin-top: 40px !important;
  }
}
small {
  font-size: 12.8px !important;
}
.c-header-nav {
  min-height: initial;
}
.nxt-45 {
  padding: 10px 25px;
}
.justify {
  text-align: center;
  text-justify: inter-word;
}
@media (max-width: 760px)  {
  .public-job-board{
    overflow: auto !important;
  }
}
</style>
